@import 'compass';
@import 'compass/support';

@import 'setting';
@import 'mixin';

/* ======================================================================
 p_404
====================================================================== */
.p_404 {
}
